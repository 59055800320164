

@font-face {
    font-family: 'verizon_nhg_edsbold';
    src: url('./assets/fonts/verizonnhgeds-bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/verizonnhgeds-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.test{
	position:absolute;
	z-index:2000;
	cursor:pointer;
	width:100vw;
	height:100vh;
	margin:50px;
	background-color:rgba(90,0,0,0.5);
	display:none;
}

button{
	position:absolute;
	z-index:100;
}

body{
  background-color: #181a20;
  color: white;
  font-family: 'verizon_nhg_edsbold', sans-serif;
  box-sizing: border-box;
  overflow: hidden;
  letter-spacing: .04rem;
  cursor:pointer;
  /* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}
#root, .App{
	/* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}

.hidden{
  display: none !important;
}

.invisible{
  opacity: 0;
}
video, #transVideo{
	background-color: transparent !important;
}

.content-wrapper{
	  position: relative;
	  
  overflow: hidden;
  /* cursor:url('assets/images/pointer_red_35.png'), pointer; */
  
}
.overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  z-index: 1000;
  
/* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}

#transVideo{
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent !important;
  z-index:10;
}

#mainVideo{
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent !important;
  z-index:1;
}
.label {
  position: absolute;
  opacity:0.85;
  font-size: 1.85vh;
  color: white;
  text-align: center;
background-color:#252525;
border-radius:6px;
padding:7px 12px 7px 12px;


}

.label:active{
  background-color:#fff;
  color:#202020;
}


.menu-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 27%;
  height: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;
  /* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}

.menu-list{
  /* position: absolute; */
  visibility: hidden;
  width:100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;
  margin-bottom:25px;
}

.menu-item{
  opacity:0.9;
  font-size: 1.85h;
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items: flex-start;
  color: #000;
  /* text-align: center; */
  background-color:#fff;
  border-radius:6px;
  padding:5px 5px 15px 8px;
  margin:0 0 10px 0;
  min-height:4.5vh;
  box-sizing:border-box;
white-space:pre-line;
text-align:left;
}

.menu-heading{
  opacity:0.9;
  font-size: 1.9vh;
  width:100%;
  
  color: #fff;
  text-align: left;
  background-color:#000;
  border-radius:6px;
  padding:13px 15px 13px 15px;
  margin:0 0 10px 0;
box-sizing: border-box;
}

.App {
  text-align: center;
}

.toolbar{
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;  

  /* margin-bottom:Min(100vh, calc(100vh- 100vw / 1.77)); */
/* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}

.toolbar-item{
	opacity:0.9;
	font-size: 1.85vh;
	color: white;
	text-align: center;
  background-color:#2D2B2B;
  border-radius:6px;
  padding:7px 12px 7px 12px;
  margin:0 5px 0 5px;
  
/* cursor:url('assets/images/pointer_red_35.png'), pointer; */

}


.toolbar-item.audio{
  background-color:transparent;
  width:1.15vw;
  height:1.15vw;
 background-image: url('assets/images/Audio_Enabled_Button_00000.png');
 background-repeat:no-repeat;
 background-size:contain;
}


.toolbar-item.audio.disabled{
	
	background-image: url('assets/images/Audio_Disabled_Button_00000.png');
}

.toolbar-item.network{
  background-color:transparent;
  width:1.15vw;
  height:1.15vw;
 background-image: url('assets/images/SystemArchitecture_Enabled_Button_06907.png');
 background-repeat:no-repeat;
 background-size:contain;
}



.toolbar-item.network.disabled{
	
	background-image: url('assets/images/SystemArchitecture_Disabled_Button_06907.png');
}

.toolbar-item.map{
  background-color:transparent;
  width:1.15vw;
  height:1.15vw;
 background-image: url('assets/images/SiteMap_Enabled_Button_00000.png');
 background-repeat:no-repeat;
 background-size:contain;
}



.toolbar-item.map.disabled{
	
	background-image: url('assets/images/SiteMap_Disabled_Button_00000.png');
}


.toolbar-item.tour.disabled{
	background-color: white;
  color:#2D2B2B;
}
	


.back{
	position:absolute;
	left:20px;
	margin-left:Max(calc((100vw - 100vh* 1.777) / 2),0px);
	font-size:1.75em;
	margin-bottom:12px;
/* cursor:url('assets/images/pointer_red_35.png'), pointer; */
}

.sub-tools{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* media query for landscape and portrait modes*/
@media (orientation: landscape) {
  .content-wrapper{
	
	width: Min(calc(100vh* 1.77), 100vw);
        height: Min(100vh, calc(100vw / 1.77));
        margin-left: Max(calc((100vw - 100vh* 1.777) / 2),0px);
		
  }
  .toolbar{
	margin-top:Min( calc( 100vh - 50px ), calc( (100vw / 1.77 - 50px) )) !important;
  }
}
@media (orientation: portrait) {
  .content-wrapper{
	width: 100vw;
	  height: calc(100vw / 1.77);;
	  margin-top: calc((100vh - 100vw / 1.777) / 2);
	
  }
  .toolbar{
	margin-top:calc( ( 100vw / 1.777) + (100vh - 100vw / 1.777) / 2 - 50px ) !important;
  }

  
}


@media (max-width: 1020px) {

	.menu-wrapper{
		width: 28% !important;
	}
  
	.menu-list{
		margin-bottom:10px;;
	}
	.menu-item{
		
		font-size: 1.15vh;
		padding:3px 2px 3px 6px;
		margin:0 0 7px 0;
	  min-height:3vh;
	  }
	  
	  .menu-heading{
		
		font-size: 1.34vh;
		
		padding:8px;
	
	  }
	}

@media (max-width: 600px)and (max-height:1000px) {

	.menu-wrapper{
		width: 29% !important;
	}
  
	.menu-heading{
		margin-bottom:5px;
		font-size:1vw;
		}
	  
	.menu-list{
		margin-bottom:4px;;
	}
	.menu-item{
		
		font-size: 1.5vw;
		padding:3px 2px 3px 6px;
		margin:0 0 4px 0;
	  min-height:2vh;
	  }
	  
	 
	}

@media (max-height: 600px) {
	.menu-wrapper{
		width: 27% !important;
	}
	.menu-list{
		margin-bottom:10px;;
	}

	.menu-heading{
	margin-bottom:5px;
	}
  
.menu-item{
	
	font-size: 2.2vh;
	padding:3px 2px 10px 4px;
	margin:0 0 4px 0;
  min-height:3.32vh;
  }
  
  .menu-heading{
	
	font-size: 1.9vh;
	
	padding:8px;

  } 
}



.quickFadeOut{
	  animation: fadeOutElement .5s;
  animation-fill-mode:forwards;
  animation-direction: normal;

}

.quickFadeIn{
	  animation: fadeInElement .3s;
  animation-fill-mode:forwards;
  animation-direction: normal;

}
/* keyframe fade out transition*/
.fadeOut{
  animation: fadeOutElement .4s;
  animation-fill-mode:forwards;
  animation-direction: normal;

	/* visibility: hidden;
	opacity: 0;
	transition: visibility 0s .4s, opacity .4s linear;
  background-color: transparent !important; */
}

@keyframes fadeOutElement {
  0% {
	opacity: 1;
  }
  100% {
	/* visibility:hidden; */
	opacity: 0;
  }
}

@keyframes fadeInElement {
  0% {
	opacity: 0;
  }
  100% {
	 visibility:visible; 
	opacity: 1;
  }
}
